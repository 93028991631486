import { ref, reactive } from 'vue'
interface ImageParamsState {
  frameName?: string;
  edition?: string;
  pythonEdition?: string;
  structure?: string;
  toolkitEdition?: string;
  type: number,
  imageId:string
}
interface FormParamsState {
  regionId: string;
  gpuLaveCount?: number | undefined;
  videoMemory: number;
  gpuPrice: number;
  gpuModelList?: Array<number>;
  gpuAmount: number;
  sort?: number;
  costType?:number
}
interface ImageFormState {
  image: any;
}
interface SpecificationParamsState {
  cpuCoreNum?: number;
  memory?: number;
  hardDisk?: number;
  gpuNum: number | string;
  imageName?: string;
  cacheDiskMaxLimit?: number;
  configAmount: number;
}
const costColumns = ref([
  {
    title: '计费项',
    dataIndex: 'costName'
  },
  {
    title: '原价',
    dataIndex: 'originalPrice',
    key: 'originalPrice'
  },
  {
    title: '优惠金额',
    dataIndex: 'discountPrice',
    key: 'discountPrice'
  },
  {
    title: '最终会员价',
    dataIndex: 'finalPrice',
    key: 'finalPrice'
  }
])
const routeData = ref<any>([])
const imageForm: ImageFormState = reactive({
  image: []
})
const imageState: ImageParamsState = reactive({
  frameName: '',
  edition: '',
  pythonEdition: '',
  structure: '',
  toolkitEdition: '',
  type: 0,
  imageId: ''
})
const formState: FormParamsState = reactive({
  regionId: '',
  videoMemory: 80,
  gpuPrice: 100,
  gpuModelList: [],
  gpuAmount: 1
  // costType: 0
})
const specificationList: SpecificationParamsState = reactive({
  cpuCoreNum: 0,
  memory: 0,
  hardDisk: 0,
  gpuNum: 0,
  imageName: '',
  cacheDiskMaxLimit: 0,
  configAmount: 0
})
const imageFlag = ref<boolean>(true)
const disabledOnceBtn = ref<boolean>(false)
const selectRowData = ref<any>([])
const selectedIds = ref<any>([])
const costType = ref()
const rentTime = ref()
const hostData = ref<any>([])
const nodePlaceOptions = ref<any>([])
const plainOptions = ref<any>([])
const chooseGpuOption = ref<any>([])
const payType = ref<boolean>(true)
const couponTotalPrice = ref<number>(0)
const couponDeratePrice = ref<number>(0)
const couponPayId = ref<string>('')
const couponOptions = ref<any>([])
const coupon = ref<string>('')
const selectCouponVisible = ref<boolean>(false)
const accountBalance = ref<number>(0)
const costDiscount = ref<any>(0)
const costDataSource = ref<any>([])
const changeImageVisible = ref<boolean>(true)
const image = ref()
const imageOptions = ref<any>([])

export {
  imageForm,
  imageFlag,
  disabledOnceBtn,
  selectRowData,
  selectedIds,
  imageState,
  routeData,
  costType,
  rentTime,
  hostData,
  nodePlaceOptions,
  plainOptions,
  chooseGpuOption,
  payType,
  couponTotalPrice,
  couponDeratePrice,
  couponPayId,
  changeImageVisible,
  image,
  imageOptions,
  couponOptions,
  coupon,
  selectCouponVisible,
  accountBalance,
  costDiscount,
  costDataSource,
  costColumns,
  specificationList,
  formState
}
